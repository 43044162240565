var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('div', [_c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-sm-1 mb-1 mb-sm-0",
    attrs: {
      "placeholder": "Title",
      "type": "text"
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.searchTerm,
      callback: function callback($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mr-sm-1 mb-1 mb-sm-0 custom-font",
    attrs: {
      "id": "type",
      "placeholder": "Select Recipient",
      "options": _vm.noticeRecieverOptoins,
      "reduce": function reduce(option) {
        return option.value;
      },
      "label": "name"
    },
    on: {
      "input": _vm.filterNoticeByType
    },
    model: {
      value: _vm.filterNoticeTypeValue,
      callback: function callback($$v) {
        _vm.filterNoticeTypeValue = $$v;
      },
      expression: "filterNoticeTypeValue"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "4",
      "xs": "12"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "Enter Date Range",
      "config": {
        mode: 'range',
        defaultDate: [this.filterStartDate, this.filterEndDate]
      }
    },
    on: {
      "input": _vm.filterByDate
    },
    model: {
      value: _vm.filterRangeDate,
      callback: function callback($$v) {
        _vm.filterRangeDate = $$v;
      },
      expression: "filterRangeDate"
    }
  })], 1)], 1)], 1)]), _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.NOTICE_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Publish New Notice ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table striped table-custom-style",
      "rows": _vm.rows,
      "columns": _vm.columns,
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "sort-options": {
        enabled: true,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'desc'
        }]
      },
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$column2, _vm$noticeRecieverOpt, _props$row2, _props$column3, _props$row3, _props$row4, _props$row5;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'format_title' ? [_c('span', [_c('b-link', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_vm._v(" " + _vm._s(props === null || props === void 0 ? void 0 : (_props$row = props.row) === null || _props$row === void 0 ? void 0 : _props$row.title) + " ")])], 1)] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'format_type' ? [_c('span', [_vm._v(_vm._s((_vm$noticeRecieverOpt = _vm.noticeRecieverOptoins[(props === null || props === void 0 ? void 0 : (_props$row2 = props.row) === null || _props$row2 === void 0 ? void 0 : _props$row2.type) - 1]) === null || _vm$noticeRecieverOpt === void 0 ? void 0 : _vm$noticeRecieverOpt.name))])] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_total_notify_users' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [(props === null || props === void 0 ? void 0 : (_props$row3 = props.row) === null || _props$row3 === void 0 ? void 0 : _props$row3.total_notify_users) === 0 ? [_c('span', [_vm._v("Sending....")])] : (props === null || props === void 0 ? void 0 : (_props$row4 = props.row) === null || _props$row4 === void 0 ? void 0 : _props$row4.total_notify_users) > 0 ? [_c('span', [_vm._v(_vm._s(props === null || props === void 0 ? void 0 : (_props$row5 = props.row) === null || _props$row5 === void 0 ? void 0 : _props$row5.total_notify_users))])] : _vm._e()], 2)] : _vm._e(), props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.NOTICE_DETAILS_ACCESS, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "EyeIcon",
            "title": "View",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['50', '100', '200']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-notice-form",
      "centered": "",
      "title": 'Publish Notice',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "noticeValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Notice Title",
      "label-for": "title"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "title",
      "vid": "title"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Notice Title",
            "rules": "required|max:255"
          },
          model: {
            value: _vm.title,
            callback: function callback($$v) {
              _vm.title = $$v;
            },
            expression: "title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Recipient Type",
      "label-for": "type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "recipient",
      "vid": "type"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          attrs: {
            "id": "type",
            "placeholder": "Select Recipient Type",
            "options": _vm.noticeRecieverOptoins,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          on: {
            "input": _vm.setSelectedRecieverType
          },
          model: {
            value: _vm.noticeRecieverTypeValue,
            callback: function callback($$v) {
              _vm.noticeRecieverTypeValue = $$v;
            },
            expression: "noticeRecieverTypeValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.searchInputType == 'custom' ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Reciever Email ",
      "label-for": "custom_reciever"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "reciever email",
      "vid": "custom_reciever",
      "rules": "email|required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          attrs: {
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "multiple": "",
            "taggable": "",
            "push-tags": "",
            "placeholder": "Type Here...",
            "label": "title"
          },
          model: {
            value: _vm.customRecievers,
            callback: function callback($$v) {
              _vm.customRecievers = $$v;
            },
            expression: "customRecievers"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 455230776)
  })], 1)] : _vm._e(), _vm.searchInputType == 'defined' ? [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Recipient",
      "label-for": "reciever"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "reciever",
      "vid": "reciever",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('v-select', {
          attrs: {
            "multiple": "",
            "options": _vm.recieverIdOption,
            "reduce": function reduce(dept) {
              return dept.id;
            },
            "label": "name",
            "placeholder": "Search Here...",
            "disabled": _vm.recieverIdOption.length > 0 ? false : true
          },
          model: {
            value: _vm.recievers,
            callback: function callback($$v) {
              _vm.recievers = $$v;
            },
            expression: "recievers"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1002140967)
  })], 1)] : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Except Employee",
      "label-for": "except_employee"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "except_employee",
      "vid": "except_employee"
    }
  }, [_c('v-select', {
    attrs: {
      "id": "except_employee",
      "placeholder": "Select Except Employee",
      "options": _vm.exceptEmployeeOptions,
      "reduce": function reduce(item) {
        return item === null || item === void 0 ? void 0 : item.id;
      },
      "label": "name",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-avatar', {
          staticClass: "mr-1",
          attrs: {
            "src": (data === null || data === void 0 ? void 0 : data.avatar) === '' ? '/avatar.svg' : data === null || data === void 0 ? void 0 : data.avatar
          }
        }), _c('div', [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.name) + " "), _c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "warning"
          }
        }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.employee_number))])], 1), _c('div', {
          staticClass: "font-small-2"
        }, [_vm._v(" " + _vm._s(data === null || data === void 0 ? void 0 : data.email) + " ")])])], 1)];
      }
    }]),
    model: {
      value: _vm.exceptEmployeeList,
      callback: function callback($$v) {
        _vm.exceptEmployeeList = $$v;
      },
      expression: "exceptEmployeeList"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Notice Description",
      "label-for": "description"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "description",
      "vid": "description"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('quill-editor', {
          style: {
            height: '13rem !important',
            paddingBottom: '5rem !important'
          },
          attrs: {
            "options": _vm.editorOption
          },
          model: {
            value: _vm.description,
            callback: function callback($$v) {
              _vm.description = $$v;
            },
            expression: "description"
          }
        }, [_c('div', {
          attrs: {
            "slot": "toolbar",
            "id": "toolbar"
          },
          slot: "toolbar"
        }, [_c('span', {
          staticClass: "ql-formats"
        }, [_c('button', {
          staticClass: "ql-bold"
        }, [_vm._v("Bold")]), _c('button', {
          staticClass: "ql-italic"
        }, [_vm._v("Italic")]), _c('button', {
          staticClass: "ql-underline"
        }, [_vm._v("Underline")]), _c('button', {
          staticClass: "ql-strike"
        }, [_vm._v("Strike")]), _c('button', {
          staticClass: "ql-blockquote"
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "ordered"
          }
        }), _c('button', {
          staticClass: "ql-list",
          attrs: {
            "value": "bullet"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "sub"
          }
        }), _c('button', {
          staticClass: "ql-script",
          attrs: {
            "value": "super"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "-1"
          }
        }), _c('button', {
          staticClass: "ql-indent",
          attrs: {
            "value": "+1"
          }
        }), _c('button', {
          staticClass: "ql-direction",
          attrs: {
            "value": "rtl"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": ""
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "center"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "right"
          }
        }), _c('button', {
          staticClass: "ql-align",
          attrs: {
            "value": "justify"
          }
        }), _c('select', {
          staticClass: "ql-color"
        }), _c('select', {
          staticClass: "ql-background"
        }), _c('select', {
          staticClass: "ql-size"
        }, [_c('option', {
          attrs: {
            "value": "small"
          }
        }), _c('option', {
          attrs: {
            "selected": ""
          }
        }), _c('option', {
          attrs: {
            "value": "large"
          }
        }), _c('option', {
          attrs: {
            "value": "huge"
          }
        })]), _c('select', {
          staticClass: "ql-font"
        }), _c('select', {
          staticClass: "ql-header"
        }, [_c('option', {
          attrs: {
            "value": "1"
          }
        }, [_vm._v("Heading 1")]), _c('option', {
          attrs: {
            "value": "2"
          }
        }, [_vm._v("Heading 2")]), _c('option', {
          attrs: {
            "value": "3"
          }
        }, [_vm._v("Heading 3")]), _c('option', {
          attrs: {
            "value": "4"
          }
        }, [_vm._v("Heading 4")]), _c('option', {
          attrs: {
            "value": "5"
          }
        }, [_vm._v("Heading 5")]), _c('option', {
          attrs: {
            "value": "6"
          }
        }, [_vm._v("Heading 6")]), _c('option', {
          attrs: {
            "selected": ""
          }
        }, [_vm._v("Normal")])]), _c('button', {
          staticClass: "ql-link"
        })])])]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1), _vm.isNoticeSendFromSubmit ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" Send Notice ")])]], 2)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }